<template>
  <div class="uk-margin-top uk-margin-bottom">
    <div class="uk-card uk-card-default uk-padding-small kandang-card">
      <FilterFarm />
      <TableFarm />
    </div>
  </div>
</template>
<script>
import FilterFarm from './filter'
import TableFarm from './table'

export default {
  components: {
    FilterFarm,
    TableFarm
  }
}
</script>
