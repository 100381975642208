<template>
  <thead class="thead-table-paranje">
    <tr>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">No RHPP</span>
          <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
            <div v-if="sortCode == 'DownSelectedNumber'">
              <div class="sort-icon">
                <img
                  :src="`${images}/icon/Vectorupper.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('numberUp'), changeSort('order_by', 'number', 'sort_by', 'ASC')"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  :src="`${images}/icon/VectordownInnactive.svg`"
                  alt=""
                  class="img-click"
                >
              </div>
            </div>
            <div v-else-if="sortCode == 'numberUp'">
              <div class="sort-icon">
                <img
                  :src="`${images}/icon/VectorupperInnactive.svg`"
                  alt=""
                  class="img-click"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  :src="`${images}/icon/Vectordown.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('DownSelectedNumber'), changeSort('order_by', 'number', 'sort_by', 'DESC')"
                >
              </div>
            </div>
            <div v-else>
              <div class="uk-margin-small-bottom">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('numberUp'), changeSort('order_by', 'number', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('DownSelectedNumber'), changeSort('order_by', 'number', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Kode Siklus</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'DownSelectedCycle'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('UpSelectedCycle'), changeSort('order_by', 'log_cycle_code', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'UpSelectedCycle'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('DownSelectedCycle'), changeSort('order_by', 'log_cycle_code', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('UpSelectedCycle'), changeSort('order_by', 'log_cycle_code', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('DownSelectedCycle'), changeSort('order_by', 'log_cycle_code', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-margin-small-bottom">
          <span class="uk-text-bold">Nama Mitra</span>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-margin-small-bottom">
          <span class="uk-text-bold">Nama Kandang</span>
        </div>
      </th>
      <th class="uk-table-small">
        <span class="uk-text-bold">Siklus Ke</span>
      </th>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Tanggal Chick Out</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'DownSelectedChick'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('UpSelectedChick'), changeSort('order_by', 'chick_out', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'UpSelectedChick'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('DownSelectedChick'), changeSort('order_by', 'chick_out', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('UpSelectedChick'), changeSort('order_by', 'chick_out', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('DownSelectedChick'), changeSort('order_by', 'chick_out', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Tanggal Terdaftar</span>
          <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
            <div
              v-if="!sortCode"
              class="uk-float-right uk-flex-column uk-width-auto"
            >
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/Vectorupper.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('UpSelectedCreated'), changeSort('order_by', 'created_at', 'sort_by', 'ASC')"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/Vectordown.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('sortCreateDown'), changeSort('order_by', 'created_at', 'sort_by', 'DESC')"
                >
              </div>
            </div>
            <div v-else-if="sortCode == 'DownSelectedCreated'">
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/Vectorupper.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('UpSelectedCreated'), changeSort('order_by', 'created_at', 'sort_by', 'ASC')"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/VectordownInnactive.svg`"
                  alt=""
                  class="img-click"
                >
              </div>
            </div>
            <div v-else-if="sortCode == 'UpSelectedCreated'">
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                  alt=""
                  class="img-click"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/Vectordown.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('DownSelectedCreated'), changeSort('order_by', 'created_at', 'sort_by', 'DESC')"
                >
              </div>
            </div>
            <div v-else>
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/Vectorupper.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('UpSelectedCreated'), changeSort('order_by', 'created_at', 'sort_by', 'ASC')"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/Vectordown.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('DownSelectedCreated'), changeSort('order_by', 'created_at', 'sort_by', 'DESC')"
                >
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Terakhir Diubah</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'sortCreateDown'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('sortCreateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'sortCreateUp'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('sortCreateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('sortCreateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('sortCreateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-width-small">
        <span class="uk-text-bold">Admin Sales</span>
      </th>
      <th class="uk-width-small">
        <span class="uk-text-bold">Kepala Cabang</span>
      </th>
      <th class="uk-width-small">
        <span class="uk-text-bold">Mitra</span>
      </th>
      <th class="uk-width-small">
        <span class="uk-text-bold">SJ DOC</span>
      </th>
      <th class="uk-width-small">
        <span class="uk-text-bold">SJ OVK</span>
      </th>
      <th class="uk-width-small">
        <span class="uk-text-bold">SJ Pakan</span>
      </th>
      <th class="uk-width-small">
        <span class="uk-text-bold">Data Timbang</span>
      </th>
      <th class="uk-table-expand">
        <span class="uk-text-bold uk-display-block uk-text-center">Aksi</span>
      </th>
    </tr>
  </thead>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { PREFIX_IMAGE } from "@/utils/constant"

export default {
  data() {
    return {
      images: PREFIX_IMAGE,
      sortCode: "DownSelectedNumber"
    }
  },
  computed: {
    ...mapGetters({
      meta: "rhpp/meta"
    })
  },
  methods: {
    ...mapMutations({
      setMeta: "rhpp/SET_META"
    }),
    changeSort(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMeta({
        ...this.meta,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    },
    sortSwitch(a) {
      this.sortCode = a
    }
  }
}
</script>
