<template>
  <div
    id="modal-delete-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-delete-farm"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="uk-modal-title uk-text-danger">
        <img
          v-lazy="`${images}/icon/warning.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Hapus RHPP
      </div>
      <p style="font-size:15px">
        Apakah kamu yakin ingin menghapus kandang <br> "{{ modalDelete.cage_name }}" ?
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-default"
          type="button"
          :disabled="is_loading"
          @click="handleDelete"
        >
          <span
            v-if="is_loading"
            uk-spinner
            class="button-spinner"
          />Ya, hapus
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-left uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Tidak
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE,
      pathName: this.$route.fullPath
    }
  },
  computed : {
    ...mapGetters({
      modalDelete: 'rhpp/modalDelete',
      meta: 'rhpp/meta'
    })
  },
  methods: {
    ...mapActions({
      getDeleteRhpp: 'rhpp/getDeleteRhpp',
      getListRhpp: 'rhpp/getListRhpp'
    }),
    async handleDelete () {
      try {
        this.is_loading = true
        await this.getDeleteRhpp(this.modalDelete.id)
        const res = await this.$store.getters['rhpp/dataDeleteRhpp']
        if (res.message === 'OK') {
          notificationSuccess(`Hapus RHPP Dengan Nama Kandang "${this.modalDelete.cage_name}" Berhasil`)
          if (this.pathName.includes('detail')) {
            return this.$router.push('/admin/rhpp')
          }
          await this.getListRhpp(this.meta)
          window.UIkit.modal('#modal-delete-confirm').hide()
        } else {
          window.UIkit.modal('#modal-delete-failed').show()
        }
        this.is_loading = false
        window.UIkit.modal('#modal-delete-confirm').hide()
      } catch (error) {
        window.UIkit.modal('#modal-delete-failed').show()
        this.is_loading = false
      }
    }
  }
}
</script>
