<template>
  <div>
    <div class="filter-container">
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="cari"
            >No RHPP</label>
            <div class="uk-form-controls">
              <input
                id="search-name"
                v-model="metaFilter.number"
                class="uk-input"
                type="number"
                placeholder="Cari No. RHPP"
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="status"
            > Siklus Ke </label>
            <div class="uk-form-controls">
              <input
                id="cycle-count"
                v-model="metaFilter.cycle_count"
                class="uk-input"
                type="number"
                placeholder="Cari Siklus"
              >
            </div>
          </div>
        </div>

        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="total_cages"
            > Kode Kandang </label>
            <div class="uk-form-controls">
              <input
                id="search-cage-code"
                v-model="metaFilter.cage_code"
                class="uk-input"
                type="text"
                placeholder="Cari kode kandang"
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="tanggal_diubah"
            >Tanggal Chick Out</label>
            <div class="uk-form-controls">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="joinStart"
                    v-model="metaFilter.date_chickout_start"
                    input-class="uk-input"
                    placeholder="DD MM YYYY"
                  />
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="joinEnd"
                    v-model="metaFilter.date_chickout_end"
                    input-class="uk-input"
                    placeholder="DD MM YYYY"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="lokasi"
            >Nama Mitra</label>
            <div class="uk-form-controls">
              <input
                id="search-partner-name"
                v-model="metaFilter.partner_name"
                class="uk-input"
                type="text"
                placeholder="Cari nama mitra"
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-text-right">
            <button
              class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
              @click="clearFilter()"
            >
              <span
                uk-icon="icon: close; ratio: 0.9"
                class="uk-margin-small-right"
              />Clear
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              @click="handleFind()"
            >
              <span
                uk-icon="icon: search; ratio: 0.9"
                class="uk-margin-small-right"
              />Cari
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-margin">
      <router-link
        v-if="isCanAccessUser(`add`, `RHPP`)"
        class="uk-button uk-button-default"
        to="/admin/rhpp/add"
      >
        <span
          uk-icon="icon: plus; ratio: 0.7"
          class="uk-margin-small-right"
        />Tambah RHPP
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"
import Datepicker from "vuejs-datepicker"
import { dateString } from "@/utils/formater"
import { isCanAccess } from "@/utils/auth"

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      metaFilter: {
        number: "",
        cage_code: "",
        partner_name: "",
        cycle_count: "",
        date_chickout_start: "",
        date_chickout_end: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      meta: "rhpp/meta"
    })
  },
  watch: {
    "metaFilter.date_chickout_start"() {
      this.metaFilter.date_chickout_start = this.dateFormatFilter(this.metaFilter.date_chickout_start)
    },
    "metaFilter.date_chickout_end"() {
      this.metaFilter.date_chickout_end = this.dateFormatFilter(this.metaFilter.date_chickout_end)
    }
  },
  async mounted() {
    this.clearFilter()
  },
  methods: {
    dateFormatFilter(date) {
      return dateString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapMutations({
      setMeta: "rhpp/SET_META"
    }),
    handleFind() {
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    async clearFilter() {
      this.metaFilter.number = "", 
      this.metaFilter.cage_code = "",
      this.metaFilter.partner_name = "",
      this.metaFilter.cycle_count = "",
      this.metaFilter.date_chickout_start = "",
      this.metaFilter.date_chickout_end = ""

      await this.handleFind()
    }
  }
}
</script>
